//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.
body{
  background-color: red;
}




