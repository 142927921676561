/*-----------------------------------------------
|   Back to top
-----------------------------------------------*/
.bottom-to-top {
  position: fixed;
  bottom: 1.2rem;
  right: 1.2rem;
  z-index: 999;
  height: 2rem;
  width: 2rem;
  background:$primary;
  text-align: center;
  line-height: 1.75rem;
  font-size: 14px;
  color:$white;
  display: none;
  transition: background-color .3s;

  @include hover-focus{
    color:#eee;
    background:$success;

  }
}
